const insuranceRouter = {
  route: '/insurance',
  title: '保险',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Postcard',
  isElementIcon: true,
  filePath: 'view/insurance', // 文件路径
  inNav: true,
  children: [
    {
      title: '社保及公积金',
      type: 'folder',
      inNav: true,
      icon: '',
      children: [
        {
          title: '社保缴纳明细',
          type: 'view',
          name: 'employeeSocialSecurityList',
          route: '/insurance/employee-social-security/list',
          filePath: 'view/insurance/social-security/employee-social-security-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '增员办理',
          type: 'view',
          name: 'socialSecurityIncrease',
          route: '/insurance/employee-social-security/increase',
          filePath: 'view/insurance/social-security/increase-service-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '批量增员',
          type: 'view',
          name: 'socialSecurityIncreaseBatch',
          route: '/insurance/employee-social-security/batch-increase',
          filePath: 'view/insurance/social-security/batch-increase-service.vue',
          inNav: true,
          icon: '',
          permission: ['批量导入社保公积金增员数据', '提交批量社保公积金增员'],
        },
        {
          title: '减员办理',
          type: 'view',
          name: 'socialSecuritySecrease',
          route: '/insurance/employee-social-security/decrease',
          filePath: 'view/insurance/social-security/decrease-service-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '批量减员',
          type: 'view',
          name: 'socialSecurityDecreaseBatch',
          route: '/insurance/employee-social-security/batch-decrease',
          filePath: 'view/insurance/social-security/batch-decrease-service.vue',
          inNav: true,
          icon: '',
          permission: ['批量导入社保公积金减员数据', '提交批量社保公积金减员'],
        },
        {
          title: '补缴办理',
          type: 'view',
          name: 'socialSecurityBujiao',
          route: '/insurance/employee-social-security/bujiao',
          filePath: 'view/insurance/social-security/bujiao-service-list.vue',
          inNav: true,
          icon: '',
        },
      ]
    },
    {
      title: '商业保险',
      type: 'folder',
      inNav: true,
      icon: '',
      children: [
        {
          title: '保险缴纳明细',
          type: 'view',
          name: 'employeeInsuranceList',
          route: '/insurance/employee-insurance/list',
          filePath: 'view/insurance/insurance/employee-insurance-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '保险增员办理',
          type: 'view',
          name: 'insuranceIncrease',
          route: '/insurance/employee-insurance/increase',
          filePath: 'view/insurance/insurance/insurance-service-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '保险批量增员',
          type: 'view',
          name: 'reserveFundIncreaseBatch',
          route: '/insurance/employee-insurance/batch-increase',
          filePath: 'view/insurance/insurance/batch-increase-service.vue',
          inNav: true,
          icon: '',
          permission: ['批量导入商业险增员数据', '提交批量商业险增员'],
        },
        {
          title: '保险减员办理',
          type: 'view',
          name: 'insuranceDecrease',
          route: '/insurance/employee-insurance/decrease',
          filePath: 'view/insurance/insurance/decrease-service-list.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '保险批量减员',
          type: 'view',
          name: 'reserveFundDecreaseBatch',
          route: '/insurance/employee-insurance/batch-decrease',
          filePath: 'view/insurance/insurance/batch-decrease-service.vue',
          inNav: true,
          icon: '',
          permission: ['批量导入商业险减员数据', '提交批量商业险减员'],
        },
      ]
    },
  ]
}

export default insuranceRouter
