const systemRouter = {
  route: null,
  name: null,
  title: '系统设置',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Setting',
  isElementIcon: true,
  filePath: 'view/bill/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '系统字典',
      type: 'view',
      name: 'DictList',
      route: '/system/dict/list',
      filePath: 'view/system/dict/dict-list.vue',
      inNav: true,
      icon: '',
      permission: ['系统字典']
    },
    {
      title: '税费配置',
      type: 'view',
      name: 'BillingTypeConfig',
      route: '/billing-type/config',
      filePath: 'view/system/billing-type/billing-type-config.vue',
      inNav: true,
      icon: '',
      permission: ['税费分页查询'],

    },
    {
      title: '岗位配置',
      type: 'view',
      name: 'PositionConfig',
      route: '/position/config',
      filePath: 'view/system/position/position-config.vue',
      inNav: true,
      icon: '',
      permission: ['岗位分页查询'],

    },

  ],
}

export default systemRouter
