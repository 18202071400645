const permitAuthRouter = {
  route: '/permit-auth/permit-auth-list',
  name: 'PermitAuth',
  title: 'esign企业认证',
  type: 'view', // 类型: folder, tab, view
  icon: 'Pointer',
  isElementIcon: true,
  filePath: 'view/permit-auth/permit-auth-list.vue',
  order: null,
  inNav: true
}

export default permitAuthRouter
