const customerRouter = {
  route: null,
  name: null,
  title: '客户管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'UserFilled',
  isElementIcon: true,
  filePath: 'view/product/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '已签客户',
      type: 'view',
      name: 'NewCustomer',
      route: '/customer/list',
      filePath: 'view/customer/customer-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '客户账号',
      type: 'view',
      name: 'CustomerUser',
      route: '/customer-user/list',
      filePath: 'view/customer/customer-user/customer-user-list.vue',
      inNav: true,
      icon: '',
    },
    // {
    //   title: '终止客户',
    //   type: 'view',
    //   name: 'Customer',
    //   route: '/terminated-customer/list',
    //   filePath: 'view/customer/terminated-customer-list.vue',
    //   inNav: true,
    //   icon: '',
    //   isElementIcon: true,
    // },
  ],
}

export default customerRouter
