const productRouter = {
  route: '/product/product-list',
  name: 'Product',
  title: '产品管理',
  type: 'view', // 类型: folder, tab, view
  icon: 'ShoppingCartFull',
  isElementIcon: true,
  filePath: 'view/product/product-list.vue',
  order: null,
  inNav: true
}

export default productRouter
