const contractRouter = {
  route: null,
  name: null,
  title: '员工合同管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Operation',
  isElementIcon: true,
  filePath: 'view/contract/', // 文件路径
  inNav: true,
  children: [
    {
      title: '合同模板管理',
      type: 'view',
      name: 'ContractTemplate',
      route: '/contract-template/list',
      filePath: 'view/contract/contract-template/contract-template-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '合同签署',
      type: 'folder',
      name: 'ContractBatch',
      route: '/contract-batch/list',
      filePath: '',
      inNav: true,
      icon: '',
      children: [
        {
          title: '用工合同',
          type: 'view',
          name: 'ContractBatch',
          route: '/contract-batch/labor-contract',
          filePath: 'view/contract/contract-batch/labor-contract.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '离职证明',
          type: 'view',
          name: 'ContractBatch',
          route: '/contract-batch/dimission-contract',
          filePath: 'view/contract/contract-batch/dimission-contract.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '调岗通知',
          type: 'view',
          name: 'ContractBatch',
          route: '/contract-batch/post-transfer-contract',
          filePath: 'view/contract/contract-batch/post-transfer-contract.vue',
          inNav: false,
          icon: '',
        },
        {
          title: '其他',
          type: 'view',
          name: 'ContractBatch',
          route: '/contract-batch/others-contract',
          filePath: 'view/contract/contract-batch/others-contract.vue',
          inNav: true,
          icon: '',
        },
        {
          title: '员工合同明细',
          type: 'view',
          name: 'Contract',
          route: '/contract/list',
          filePath: 'view/contract/contract-list.vue',
          inNav: true,
          icon: '',
        },
      ]
    },
  ],
}

export default contractRouter
