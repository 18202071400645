const payrollRouter = {
  route: null,
  name: null,
  title: '薪资管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Money',
  isElementIcon: true,
  filePath: 'view/payroll/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '薪资组批次',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/payroll/salary-batch/list',
      filePath: 'view/payroll/salary-batch-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '新建薪资组',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/payroll/salary-batch',
      filePath: 'view/payroll/salary-batch.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '员工薪资',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/payroll/salary/list',
      filePath: 'view/payroll/employee-salary-list.vue',
      inNav: false,
      icon: '',
    },
    {
      title: '薪酬设置',
      type: 'view',
      name: 'CustomerPayroll',
      route: '/payroll/salary-config',
      filePath: 'view/payroll/salary-config.vue',
      inNav: true,
      icon: '',
    },
  ],
}

export default payrollRouter
