const businessPermitRouter = {
  route: '/business-permit/list',
  name: null,
  title: '牌照管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'Postcard',
  isElementIcon: true,
  filePath: 'view/business-permit', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '工商税务信息',
      type: 'view',
      name: 'Postcard',
      route: '/business-permit/list',
      filePath: 'view/business-permit/business-permit-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '社保公积金管理',
      type: 'view',
      name: 'SocialSecurityFund',
      route: '/data-maintenance/social-security-fund/list',
      filePath: 'view/data-maintenance/social-security-fund/social-security-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '商业险数据管理',
      type: 'view',
      name: 'Insurance',
      route: '/data-maintenance/insurance/list',
      filePath: 'view/data-maintenance/insurance/insurance-list.vue',
      inNav: true,
      icon: '',
    },
    {
      title: '银行开户信息',
      type: 'view',
      name: 'PermitBankAccount',
      route: '/data-maintenance/bank-account/list',
      filePath: 'view/data-maintenance/bank-account/bank-account-list.vue',
      inNav: true,
      icon: '',
    },
  ]
}

export default businessPermitRouter
