const additionalDeductionRouter = {
  name: 'Special',
  title: '专项附加扣除项',
  type: 'view', // 类型: folder, tab, view
  icon: 'Money',
  isElementIcon: true,
  route: '/additional-deduction/list',
  filePath: 'view/additional-deduction/additional-deduction.vue', // 文件路径
  order: null,
  inNav: true,
}

export default additionalDeductionRouter
